import { React, useEffect} from 'react';  
import { Link } from 'react-router-dom';
import '../../sass/main.scss';
import FAQ from './faq.component';
import Demo from './demo.component'
import Explainer from './explainer.component';

export default function Homepage() {
  return (
   <div className='container'>
      <div className="home-hero">
         <div className="left-column">
            <h1 className='title'>We build <strong>Intelligent Knowledge Hubs</strong> for bright businesses</h1>
            <p>What’s an Intelligent Knowledge Hub? Tailor-made, intelligent software for simplifying communication, knowledge sharing, training and customer support, all powered by AI-generated dialogues.</p>
            <Link className='button' to='/register'>Get Started</Link>
            <Link className='cta-button' to='/register'>Free Trial</Link>
         </div>
         <div className="right-column">
            <div className='placeholder'>
               <p className='text-center w-full'>Placeholder</p>
            </div>
         </div>
      </div>
      <section className='section-parent'>
         <h2 className='subtitle text-center w-full'><strong>Everything</strong> you need for your <br /> <strong>Intelligent Knowledge Base</strong></h2>
         <div className='explainer-section'>
            <Explainer />
         </div>
      </section>
      <section className='section-parent'>
         <Demo />
      </section>
      <section className='section-parent'>
         <FAQ />
      </section>
   </div>
   );
}
