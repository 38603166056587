import React from 'react';

const Integrations = (props) => {

  return (
    <div className='chatbot-container'>
        <h1>Integrations</h1>
    </div>
  );
};

export default Integrations;
