import React, { useState , useEffect} from 'react';
import { auth } from '../../../store/firebase.config';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import stateAction from '../../../store/actions/state.action';

export default function PasswordReset() {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleReset = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage('Password reset email sent. Please check your email.');
      })
      .catch((error) => {
        setErrorMessage('Password reset failed. Please try again.');
      });
  };

  return (
    <div className='container'>
      <div className='login-container'>
        <h2>Reset Password</h2>
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && <p className='form-error' style={{ color: 'red' }}>{errorMessage}</p>}
        <form onSubmit={handleReset}>
          <div className="input-container">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Enter your email'
              type="email"
              id="email"
            />
          </div>
          <button
            className="cta-button"
            type="submit"
          >
            Reset Password
          </button>
        </form>
      </div>
      <div className='login-text-below'>
          <p>Remember your password? <Link className='link' to='/login'>Login</Link></p>
        </div>
    </div>
  );
}
