import React from 'react';
import DataSidebar from './upload_data/upload-data-sidebar.component';
import FileUpload from './upload_data/file-upload.component';
import { useNavigate, Outlet } from 'react-router-dom';

const UploadData = (props) => {

  return (
    <div className='chatbot-container'>
      <h1>Upload Data</h1>
        <div className='upload-data-container'>
            <DataSidebar/>
            <Outlet />
        </div>
        
    </div>
  );
};

export default UploadData;
