import React from 'react';

const Settings = (props) => {

  return (
    <div className='chatbot-container'>
        <h1>Settings</h1>
    </div>
  );
};

export default Settings;
