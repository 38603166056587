import React from 'react';
import { Link } from 'react-router-dom';

const Demo = (props) => {

  return (
    <div className='container page-container'>
      <div className='page-header'>
      <h2 className='subtitle w-full text-center' id='demo'><strong>Live Demo</strong></h2>
         <p className='text-center block'>This AI was trained on a document explaining ChiffChaff <br /> You can embed a widget like this on any page on your website!</p>
      </div>
    <div className='chatbot-container'>
        <h1>Your Intelligent Knowledge Hub</h1>
        <iframe
            src="https://www.chatbase.co/chatbot-iframe/9hIjvsy7I7OTJCAR-AS1d"
            width="100%"
            frameborder="0"
        ></iframe>

    </div>
    </div>
  );
};

export default Demo;
