import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import '../../sass/main.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

var monthlyPrices = [
    '0,00', '9.99', '19.99', '29.99'
]

var yearlyPrices = [
    '0,00', '99.99', '199.98', '299.98'
]

export default function Pricing() {
    const [billingPlan, setBillingPlan] = useState('monthly');

    return (
        <div className='container page-container'>
            <div className='page-header'>
                <h2 className='subtitle w-full text-center'><strong>Pricing</strong></h2>
                <p className='w-full text-center'>Get 2 months free by subscribing yearly!</p>
            </div>
            <div className='toggle-container'>
                <a
                    className={`toggle ${billingPlan === 'monthly' ? 'cta-button' : 'button'}`}
                    onClick={() => setBillingPlan('monthly')}
                >
                    Monthly Billing
                </a>
                <a
                    className={`toggle ${billingPlan === 'yearly' ? 'cta-button' : 'button'}`}
                    onClick={() => setBillingPlan('yearly')}
                >
                    Yearly Billing
                </a>
            </div>

            <div className='pricing-columns'>
                <div className='pricing-column'>
                    <div className='pricing-column-header'>
                        <h3>Free</h3>
                        <span></span>
                    </div>
                    <div className='pricing-column-list'>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck} />20 message credits/month</li>
                            <li><FontAwesomeIcon icon={faCheck} />1 chatbot</li>
                            <li><FontAwesomeIcon icon={faCheck} />400,000 characters/chatbot</li>
                            <li><FontAwesomeIcon icon={faCheck} />Limit to 10 links to train on</li>
                            <li><FontAwesomeIcon icon={faCheck} />Embed on unlimited websites</li>
                            <li><FontAwesomeIcon icon={faCheck} />Upload multiple files</li>
                            <li><FontAwesomeIcon icon={faCheck} />View conversation history</li>
                            <li><FontAwesomeIcon icon={faCheck} />Chatbots get deleted after 7 days of inactivity</li>
                        </ul>
                    </div>
                    <div className='pricing-column-footer'>
                        Register
                    </div>
                </div>
                <div className='pricing-column'>
                <div className='pricing-column-header relative'>
                        <h3>Hobby</h3>
                        <span>£{billingPlan === 'monthly' ? monthlyPrices[1] : yearlyPrices[1]}</span>
                    </div>
                    <div className='pricing-column-list'>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck} />2,000 message credits/month</li>
                            <li><FontAwesomeIcon icon={faCheck} />2 chatbots</li>
                            <li><FontAwesomeIcon icon={faCheck} />11,000,000 characters/chatbot</li>
                            <li><FontAwesomeIcon icon={faCheck} />Unlimited links to train on</li>
                            <li><FontAwesomeIcon icon={faCheck} />Embed on unlimited websites</li>
                            <li><FontAwesomeIcon icon={faCheck} />Upload multiple files</li>
                            <li><FontAwesomeIcon icon={faCheck} />View conversation history</li>
                            <li><FontAwesomeIcon icon={faCheck} />Capture leads</li>
                            <li><FontAwesomeIcon icon={faCheck} />API access</li>
                            <li><FontAwesomeIcon icon={faCheck} />Zapier integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Slack integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Wordpress integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />WhatsApp integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Messenger integration (coming soon)</li>
                        </ul>
                    </div>
                    <div className='pricing-column-footer'>
                        Register
                    </div>
                </div>
                <div className='pricing-column'>
                <div className='pricing-column-header'>
                        <h3>Standard</h3>
                        <span>£{billingPlan === 'monthly' ? monthlyPrices[2] : yearlyPrices[2]}</span>
                    </div>
                    <div className='pricing-column-list'>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck} />10,000 message credits/month</li>
                            <li><FontAwesomeIcon icon={faCheck} />5 chatbots</li>
                            <li><FontAwesomeIcon icon={faCheck} />11,000,000 characters/chatbot</li>
                            <li><FontAwesomeIcon icon={faCheck} />Unlimited links to train on</li>
                            <li><FontAwesomeIcon icon={faCheck} />Embed on unlimited websites</li>
                            <li><FontAwesomeIcon icon={faCheck} />Upload multiple files</li>
                            <li><FontAwesomeIcon icon={faCheck} />View conversation history</li>
                            <li><FontAwesomeIcon icon={faCheck} />Capture leads</li>
                            <li><FontAwesomeIcon icon={faCheck} />API access</li>
                            <li><FontAwesomeIcon icon={faCheck} />Zapier integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Slack integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Wordpress integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />WhatsApp integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Messenger integration (coming soon)</li>
                            <li><FontAwesomeIcon icon={faCheck} />Option to Choose GPT-4</li>
                        </ul>
                    </div>
                    <div className='pricing-column-footer'>
                        Register
                    </div>
                </div>
                <div className='pricing-column'>
                <div className='pricing-column-header'>
                        <h3>Unlimited</h3>
                        <span>£{billingPlan === 'monthly' ? monthlyPrices[3] : yearlyPrices[3]}</span>
                    </div>
                    <div className='pricing-column-list'>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck} />10,000 message credits/month</li>
                            <li><FontAwesomeIcon icon={faCheck} />5 chatbots</li>
                            <li><FontAwesomeIcon icon={faCheck} />11,000,000 characters/chatbot</li>
                            <li><FontAwesomeIcon icon={faCheck} />Unlimited links to train on</li>
                            <li><FontAwesomeIcon icon={faCheck} />Embed on unlimited websites</li>
                            <li><FontAwesomeIcon icon={faCheck} />Upload multiple files</li>
                            <li><FontAwesomeIcon icon={faCheck} />View conversation history</li>
                            <li><FontAwesomeIcon icon={faCheck} />Capture leads</li>
                            <li><FontAwesomeIcon icon={faCheck} />API access</li>
                            <li><FontAwesomeIcon icon={faCheck} />Zapier integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Slack integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Wordpress integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />WhatsApp integration</li>
                            <li><FontAwesomeIcon icon={faCheck} />Messenger integration (coming soon)</li>
                            <li><FontAwesomeIcon icon={faCheck} />Option to Choose GPT-4</li>
                            <li><FontAwesomeIcon icon={faCheck} />Option to Choose GPT-4</li>
                            <li><FontAwesomeIcon icon={faCheck} />Remove 'Powered by ChiffChaff'</li>
                            <li><FontAwesomeIcon icon={faCheck} />Use your own custom domains</li>
                        </ul>
                    </div>
                    <div className='pricing-column-footer'>
                        Register
                    </div>
                </div>
            </div>
        </div>
    );
}
