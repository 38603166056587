import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet } from 'react-router-dom';
import Sidebar from "./layout/sidebar.component";
import { Link } from "react-router-dom";
import ChatBot from "./dashboard/chat-bot.component";
import UploadData from "./dashboard/upload-data.component";

export default function Dashboard(props) {
  const userDoc = useSelector((state) => state.user.doc);
  const uid = useSelector((state) => state.user.config?.uid); // Use optional chaining to prevent errors
  const redirect = useSelector((state) => state.state.routeFromLogin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirect) navigate(redirect);
  }, []);

  return (
    <div className="app-container">
      <Sidebar></Sidebar>
      <div className="app-dashboard">
        <h1>Welcome to ChiffChaff</h1>
        <p>
          Browse our <Link to="/">developer docs</Link> or explore{" "}
          <Link to="/">all the ways</Link> to start using ChiffChaff.
        </p>
        <Outlet />
      </div>
    </div>
  );
}
