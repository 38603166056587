import React from 'react';
import { Link } from 'react-router-dom';

const PaymentSuccess = (props) => {

  return (
    <div className='container page-container'>
      <div className='page-header'>
      <h2 className='subtitle w-full text-center'><strong>Payment Success</strong></h2>
         <p className='text-center block'>Successfull payment blah blah blah</p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
