import React from 'react';

const QaUpload = (props) => {

  return (
    <div className='file-upload-container'>
        <div className='file-upload-header'>
            <h2>Q & A</h2>
        </div>
        <div className='file-upload-body'>

        </div>
    </div>
  );
};

export default QaUpload;
