import React from 'react';
import { Link } from 'react-router-dom';

const ChatBot = (props) => {

  return (
    <div className='chatbot-container'>
        <h1>Your Intelligent Knowledge Hub</h1>
        <iframe
            src="https://www.chatbase.co/chatbot-iframe/9hIjvsy7I7OTJCAR-AS1d"
            width="100%"
            frameborder="0"
        ></iframe>

    </div>
  );
};

export default ChatBot;
