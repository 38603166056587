import React from 'react';

const Support = (props) => {

  return (
    <div className='chatbot-container'>
        <h1>Support</h1>
    </div>
  );
};

export default Support;
