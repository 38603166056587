import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const FAQ = () => {
  const faqItems = [
    {
      question: 'What is ChiffChaff?',
      answer: 'ChiffChaff is an AI chatbot builder, it trains ChatGPT on your data and lets you add a chat widget to your website. Just upload a document or add a link to your website and get a chatbot that can answer any question about their content.',
    },
    {
      question: 'What should my data look like?',
      answer: 'Currently, you can upload one or multiple files (.pdf, .txt, .doc, .docx), paste text, or add a link to your website to be scraped.',
    },
    {
        question: 'Can I give my chatbots instructions?',
        answer: "Yes, you can edit your chatbot's instructions and give it a name, personality traits and directions on how to answer questions ex. (only answer in French).",
      },
      {
        question: 'Where is my data stored?',
        answer: 'The content of the document is hosted on secure GCP/AWS servers in us-east.',
      },
      {
        question: 'Does it use GPT-3.5 or GPT-4?',
        answer: 'By default your chatbot uses gpt-3.5-turbo (but you have the option to use gpt-4 on the Standard and Unlimited plans).',
      },
      {
        question: 'How can I add my chatbot to my website?',
        answer: 'You can embed an iframe or add a chat bubble to the bottom right of your website. To do that, create a chatbot and click "Embed on website". You can also use the API to communicate with your chatbot anywhere!',
      },
      {
        question: 'Does it support other languages?',
        answer: 'Yes, Chatbase supports about 95 languages. You can have your sources in any language and ask it questions in any language.',
      },
      {
        question: 'Can I share a chatbot I created?',
        answer: 'Yes, by default any chatbot you create is private but you can change the setting to make it public and send it to anyone.',
      }
  ];

  const [openItem, setOpenItem] = useState(null);

  // Function to handle click and toggle accordion
  const toggleAccordion = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };

  return (
    <div className='container page-container'>
      <div className='page-header'>
        <h2 className='subtitle text-center w-full'><strong>Frequently Asked Questions</strong></h2>
      </div>
      {faqItems.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAccordion(index)}>
            <span>{item.question}</span>
            <FontAwesomeIcon icon={openItem === index ? faMinus : faPlus} />
          </div>
          {openItem === index && (
            <div className="faq-answer">
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
