import React, { useEffect, useState } from "react";
import { auth, db } from "../../../store/firebase.config";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { USER_SERVICE } from "../../../services/firebase/user.service";
import { useDispatch } from "react-redux";
import userAction from "../../../store/actions/user.action";
import stateAction from "../../../store/actions/state.action";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export default function Register(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = React.useState('');
    const [surNameErrorMessage, setSurNameErrorMessage] = React.useState('');
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);


    // Check if field has input to show green tick
    const isFieldValid = (input) => {
        if (input === '') {
            return "- Required";
        } else {
            return ' ';
        }
    };

    const isEmailValid = (input) => {
        if (input === '') {
            return "- Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input)) {
            return "- Invalid Email";
        } else {
            return '';
        }
    };

    const isPasswordValid = (input) => {
        if (input === '') {
            return "- Required";
        }
        if (input.length < 6) {
            return "- Password should be at least 6 characters long";
        }
        if (!/\d/.test(input)) {
            return "- Password should contain at least one digit";
        }
        if (!/[a-z]/.test(input)) {
            return "- Password should contain at least one lowercase letter";
        }
        if (!/[A-Z]/.test(input)) {
            return "- Password should contain at least one uppercase letter";
        }
        if (!/[^a-zA-Z0-9]/.test(input)) {
            return "- Password should contain at least one special character";
        }
        return '';
    };



    const validateEmail = () => {
        setEmailErrorMessage("");
        let isValid = false;
        isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        if (email == "" || email == undefined || email == null) {
            isValid = false;
        }
        if (!isValid) {
            setEmailErrorMessage("Email is invalid.")
        }
        return isValid;
    }

    const validatePassword = () => {
        setPasswordErrorMessage("");
        let isValid = true;
        if (password !== '' && confirmPassword !== '') {
            if (password !== confirmPassword) {
                isValid = false;
            }
        }
        if (!isValid) {
            setPasswordErrorMessage("Passwords do not match.");
        }
        return isValid;
    }

    const onCreateUser = (response) => {
        if (response) {
            var docData = response.data();
            dispatch(userAction.updateUser({ doc: docData }));
            toLogin();
        }
    }

    const addUser = async (user) => {
        user.email = email;
        user.firstName = firstName;
        user.surName = surname;
        USER_SERVICE.createUserDoc(user, onCreateUser);
    };

    const submitForm = (e, firstName, email, password) => {
        e.preventDefault();
        if (firstName && email && password) {
            setErrorMessage("");
            if (validatePassword() && validateEmail()) {
                createUserWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        sendEmailVerification(auth.currentUser)
                            .then(() => {
                                addUser(userCredential.user);
                            })
                            .catch(err => setErrorMessage(err.message));
                    })
                    .catch(err => setErrorMessage(err.message));
            } else {
                setErrorMessage("Please fill in all fields.");
            }
        } else {
            setErrorMessage("Please fill in all fields");
        }
        setPassword("");
        setConfirmPassword("");
    }

    function toLogin(props) {
        navigate("/login");
    }

    function toVerifyEmail(props) {
        navigate("/verifyEmail");
    }

    const isFormValid = () => {
        return (
            firstName &&
            surname &&
            email &&
            password &&
            confirmPassword &&
            !emailErrorMessage &&
            !passwordErrorMessage &&
            password === confirmPassword &&
            password.length >= 8 &&
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password)
        );
    };

    return (
        <div className='container'>
            <div className="login-container">
                <h2>Register your new account</h2>
                <form style={{ marginTop: '2em' }} onSubmit={(e) => submitForm(e, firstName, email, password)}>
                    <div className="input-container first-input">
                        <label>First Name {firstNameErrorMessage && <div className="input-error">{firstNameErrorMessage}</div>}</label>
                        <div className="input-body">
                            <input
                                value={firstName}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    setFirstName(input);
                                    const errorMessage = isFieldValid(input);
                                    setFirstNameErrorMessage(errorMessage);
                                }}
                                placeholder="Alex"
                                type="text"
                                id="surname"
                                className={firstNameErrorMessage === " " && ('green-border')}

                            />
                            <div className="input-check">
                                {isFieldValid(firstName) === "" && (
                                    <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '0.5rem' }} />
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="input-container second-input">
                        <label>Last Name {surNameErrorMessage && <div className="input-error">{surNameErrorMessage}</div>}</label>
                        <div className="input-body">
                            <input
                                value={surname}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    setSurname(input);
                                    const errorMessage = isFieldValid(input);
                                    setSurNameErrorMessage(errorMessage);
                                }}
                                placeholder="Hyslop"
                                type="text"
                                id="surname"
                                className={surNameErrorMessage === " " && ('green-border')}
                            />
                            <div className="input-check">
                                {isFieldValid(surname) === "" && (
                                    <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '0.5rem' }} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="input-container">
                        <label>E-Mail {emailErrorMessage && <div className="input-error">{emailErrorMessage}</div>}</label>
                        <div className="input-body">
                            <input
                                value={email}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    setEmail(input);
                                    const errorMessage = isEmailValid(input);
                                    setEmailErrorMessage(errorMessage);
                                }}
                                placeholder="Alex@chiffchaff.co.uk"
                                type="email"
                                id="email"
                                className={isEmailValid(email) === "" && ('green-border')}
                            />
                            <div className="input-check">
                                {isEmailValid(email) === "" && (
                                    <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '0.5rem' }} />
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="input-container first-input">
                        <label>Password {password && <div className="input-error">{isPasswordValid(password)}</div>}</label>
                        <div className="input-body">
                            <input
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                className={isPasswordValid(password) === "" && ('green-border')}
                            />
                            <span className="password-show" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? (
                                    <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} />
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="input-container second-input">
                        <label>Repeat Password {password && <div className="input-error">{isPasswordValid(confirmPassword)}</div>}</label>
                        <div className="input-body">
                            <input
                                placeholder="Repeat Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={showPassword2 ? 'text' : 'password'}
                                id="confirmPassword"
                                className={isPasswordValid(confirmPassword) === "" && ('green-border')}
                            />
                            <span className="password-show" onClick={() => setShowPassword2(!showPassword2)}>
                                {showPassword2 ? (
                                    <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} />
                                )}
                            </span>
                        </div>
                    </div>
                    {confirmPassword && password !== confirmPassword && (
                        <p className="password-error">Passwords do not match.</p>
                    )}

                    <button
                        className={`cta-button${!isFormValid() ? ' disable' : ''}`}
                        style={{ marginTop: '2rem', width: '100%' }}
                        disabled={!isFormValid()}
                        onClick={(e) => submitForm(e, firstName, email, password)}
                    >
                        Sign Up
                    </button>
                </form>
            </div>
            <div className='login-text-below'>
                <p>Already have an account? <Link className='link' to='/login'>Login</Link></p>
            </div>
        </div>
    );
}
