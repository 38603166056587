import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../../store/firebase.config';
import { useDispatch } from 'react-redux';
import userAction from '../../../store/actions/user.action';
import { USER_SERVICE } from '../../../services/firebase/user.service';
import { AuthContext } from '../../../auth-provider.component';
import { Link } from 'react-router-dom';

export default function Login(props) {
  const { login } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const submitForm = async (e, username, password) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      const currUser = userCredential.user;
      login(currUser);
      updateUserConfig(currUser);
      USER_SERVICE.getUserDocByEmail(currUser.email, onGetUserDoc);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Login credentials incorrect.");
    }
  };

  const onGetUserDoc = (response) => {
    var docData = response.docs[0].data();
    dispatch(userAction.updateUser({ doc: docData }));
  };

  const onCheckUserDocExists = (response, user) => {
    if (response) {
      if (response.size === 0) {
        USER_SERVICE.createUserDoc(user, onCreateUser);
      } else {
        response.forEach((doc) => {
          var docData = doc.data();
          dispatch(userAction.updateUser({ doc: docData }));
        });
      }
    }
  };

  const onCreateUser = (response) => {
    if (response) {
      var docData = response.data();
      dispatch(userAction.updateUser({ doc: docData }));
    }
  };

  const updateUserConfig = (user) => {
    var user2 = user;
    dispatch(userAction.updateUser({ authed: true, config: user2 }));
    USER_SERVICE.checkUserDocExistsOnUserId(user2, onCheckUserDocExists);
  };

  return (
    <div className='container'>
      <div className='login-container'>
        <h2>Sign in to your account</h2>
        {errorMessage && <p className='form-error'> {errorMessage} </p>}
        <form onSubmit={(e) => submitForm(e, username, password)}>
          <div className="input-container">
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder='Email'
              type="email"
              id="email"
            />
          </div>
          <Link className='reset-password link' to={"/password-reset"}>Forgotten your password? </Link>
          <div className="input-container">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              type="password"
              id="password"
            />
          </div>
          <button
            className="cta-button"
            onClick={(e) => submitForm(e, username, password)}
          >
            Login
          </button>
        </form>
      </div>
        <div className='login-text-below'>
          <p>Don't have an account? <Link className='link' to='/register'>Sign up</Link></p>
        </div>
    </div>
  );

}
