import './App.scss';
import NavBar from './navigation/nav-bar.component';
import AppNavBar from './navigation/app-nav-bar.component';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import Homepage from './components/website/homepage.component';
import Login from './components/app/user-admin/login.component';
import Account from './components/app/user-admin/account.component'; 
import Register from './components/app/user-admin/register.component';
import Dashboard from './components/app/dashboard.component';
import Pricing from './components/website/pricing.component';
import Footer from './navigation/footer.component';
import PasswordReset from './components/app/user-admin/password-reset.component';
import NotFound from './components/app/404.component';
import UploadData from './components/app/dashboard/upload-data.component';
import FileUpload from './components/app/dashboard/upload_data/file-upload.component';
import WebsiteUpload from './components/app/dashboard/upload_data/website-upload.component';
import TextUpload from './components/app/dashboard/upload_data/text-upload.component';
import QaUpload from './components/app/dashboard/upload_data/qa-upload.component';
import ChatBot from './components/app/dashboard/chat-bot.component';
import Settings from './components/app/dashboard/settings.component';
import Integrations from './components/app/dashboard/integrations.component';
import Support from './components/app/dashboard/support.component';
import Demo from './components/website/demo.component';
import FAQ from './components/website/faq.component';
import Checkout from './components/website/checkout.component';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Success from './components/website/payment/paymentSuccess.component';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const stripePromise = loadStripe('pk_test_51OIswuIVsoQG4nVlQ4PFn0sW6usxvQ3xiFNb8FtikgNqQnCNVWOZljDnQMii7hoLSeuZ1z0hApr4ZlTFMKbYIAk400EaO99HOJ'); // Replace with your actual publishable key


function LoadingScreen() {
  return (
    <div>
      <NavBar />
      <div className='loading-container'>
          <div className="loading-spinner"></div>
      </div>
    </div>
  );
}

function App() {
  const [authed, setAuthed] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();

    const logOut = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthed(true);
      } else {
        setAuthed(false);
      }
      setLoading(false);
    });

    return () => {
      logOut();
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const renderNavBar = () => {
    if (location.pathname.startsWith('/dashboard')) {
      return <AppNavBar />;
    } else {
      return <NavBar />;
    }
  };

  const renderFooter = () => {
    if (location.pathname === '/dashboard') {
      return null;
    } else {
      return <Footer />;
    }
  };

  return (
    <div>
      {renderNavBar()} 
      <div className='App' component="main">
        <Routes>
          {/* website routes*/}

          {/* app routes */}
          {/* no auth app routes */}
          <Route path="" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/register" element={<Register />} /> 
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/demo" element={<Demo />} />
          <Route
          path="/checkout"
          element={
            <Elements stripe={stripePromise}>
              <Checkout />
            </Elements>
          }
        />
          <Route path="/checkout/success" element={<Success />} />

          {/* authed app routes */}
          <Route path="/login" element={authed ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/account" element={authed ? <Account /> : <Login />} /> 
          <Route path="/dashboard" element={authed ? <Dashboard /> : <Navigate to="/login" />}>
            <Route index element={<ChatBot />} />
            <Route path="upload-data" element={<UploadData />}>
              <Route index element={<FileUpload />} />
              <Route path="text" element={<TextUpload />} />
              <Route path="website" element={<WebsiteUpload />} />
              <Route path="qa" element={<QaUpload />} />
            </Route>
            <Route path="integrations" element={<Integrations />} />
            <Route path="settings" element={<Settings />} />
            <Route path="support" element={<Support />} />
          </Route>

           {/* 404 page */}
           <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
      {renderFooter()}
    </div>
  );
}

export default App;
