import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = (props) => {
    const location = useLocation();

    const navItems = [
        { name: 'Home', path: '/dashboard' },
        { name: 'Upload Data', path: '/dashboard/upload-data' },
        { name: 'Integrations', path: '/dashboard/integrations' },
        { name: 'Settings', path: '/dashboard/settings' },
        { name: 'Support', path: '/dashboard/support' }
    ];

    return (
        <div className='app-sidebar'>
            <ul className='app-sidebar-links'>
                {navItems.map((item, index) => (
                    <li key={index}>
                        <Link
                            className={`mob-item ${location.pathname === item.path ? 'active' : ''}`}
                            to={item.path}
                        >
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
