import { useState, useEffect } from 'react';
import React, { createContext } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { USER_SERVICE } from './services/firebase/user.service';
import { ITEM_SERVICE } from './services/firebase/item.service';
import { useDispatch, useSelector } from 'react-redux' 
import userAction from './store/actions/user.action';
import stateAction from './store/actions/state.action';
 
const AuthContext = createContext();

function AuthProvider(props) {
    const [authed, setAuthed] = React.useState(false);
    const [token, setToken] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const dispatch = useDispatch();
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {
            const userEmail = user.email;
            USER_SERVICE.getUserDocByEmail(userEmail, onGetUserDoc);
            updateUserConfig(user);
        } 
    });

    function updateUserConfig(user) {
        console.log('refresh config dashboard:', user);
        var user2 = user;
        dispatch(userAction.updateUser({ authed: true, config: user2 }));
        USER_SERVICE.checkUserDocExistsOnUserId(user2, onCheckUserDocExists);
    }

    const onCheckUserDocExists = (response) => {
        if (response && response.size > 0) {
          response.forEach((doc) => {
            var docData = doc.data();
            dispatch(userAction.updateUser({ doc: docData }));
          });
        }
      }
     
    const onGetUserDoc = (response) => {
        var docData = response.docs[0].data(); 
        dispatch(userAction.updateUser({doc:docData}));   
    }

    const login = (token, user) => {
        setAuthed(true);
        setToken(token);
        setUser(user);
    };

    const logout = () => {
        console.log("logout authprovider");
        setAuthed(false);
        setToken(null);
    };

    const value = {
        authed,
        token,
        user,
        login,
        logout,
    };
    

    return <AuthContext.Provider value={value} {...props} />;
}

export { AuthContext, AuthProvider };