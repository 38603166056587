import React, { useState, useEffect } from 'react';

const Explainer = (props) => {
  const [activeItem, setActiveItem] = useState(null);

  // Array of objects containing list item details and image URLs
  const explainerItems = [
    {
      title: 'Customise behavior',
      description: 'Ensure that your chatbot matches your brand’s personality with custom instructions.',
      imageURL: 'https://backend.chatbase.co/storage/v1/object/public/chatbase/landing/model.png?width=1080&quality=50',
    },
    {
        title: 'Customize appearance',
        description: "Make your chatbot look like it's part of your website with custom colors and logos.",
        imageURL: 'https://backend.chatbase.co/storage/v1/object/public/chatbase/landing/chat-interface.png?width=1080&quality=50',
      },
      {
        title: 'Collect leads',
        description: 'Set your chatbot to collect leads to follow up with later.',
        imageURL: 'https://backend.chatbase.co/storage/v1/object/public/chatbase/landing/leads.png?width=1080&quality=50',
      },
      {
        title: 'Get notified',
        description: 'Get daily emails with all conversations and leads collected by your chatbots.',
        imageURL: 'https://backend.chatbase.co/storage/v1/object/public/chatbase/landing/notifications.png?width=1080&quality=50',
      },
      {
        title: 'Integrate with your tools',
        description: 'Connect your chatbot to your favorite tools like Zapier, Slack, Messenger, and more.',
        imageURL: 'https://backend.chatbase.co/storage/v1/object/public/chatbase/landing/integrations.png?width=1080&quality=50',
      },
  ];

  const handleItemClick = (index) => {
    setActiveItem((prevActiveItem) => (prevActiveItem === index ? prevActiveItem : index));
  };

  // Set the first item as active on initial load
  useEffect(() => {
    setActiveItem(0);
  }, []);

  return (
    <div className='container explainer-section'>
      <div className='explainer-parent'>
        <div className='explainer-list'>
          <ul>
            {explainerItems.map((item, index) => (
              <li
                key={index}
                onClick={() => handleItemClick(index)}
                className={index === activeItem ? 'active' : ''}
              >
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className='explainer-images'>
          <div className='explainer-inner'>
            {explainerItems.map((item, index) => (
              <img
                key={index}
                src={item.imageURL}
                style={{ display: index === activeItem ? 'block' : 'none' }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explainer;