// Checkout.js
import React, { useState } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51OIswuIVsoQG4nVlQ4PFn0sW6usxvQ3xiFNb8FtikgNqQnCNVWOZljDnQMii7hoLSeuZ1z0hApr4ZlTFMKbYIAk400EaO99HOJ'); // Replace with your actual publishable key

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [address, setAddress] = useState({});

  const handleChange = (event) => {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { token, error } = await stripe.createToken(elements.getElement(CardElement), address);

    if (error) {
      setPaymentError(error.message);
    } else {
      // Send the token to your server to handle the payment
      console.log('Card and Address Token:', token);

      setPaymentError(null);
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Card details
            <CardElement />
          </label>
        </div>
        <div>
          <label>
            Address Line 1
            <input type="text" name="line1" onChange={handleChange} required />
          </label>
        </div>
        <div>
          <label>
            Address Line 2
            <input type="text" name="line2" onChange={handleChange} />
          </label>
        </div>
        {/* Add more address fields as needed */}
        <button type="submit">Pay</button>
        {paymentError && <div className="error">{paymentError}</div>}
      </form>
    </Elements>
  );
};

export default Checkout;
