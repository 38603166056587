import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faAlignLeft, faGlobe, faComments } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

const DataSidebar = (props) => {
  const location = useLocation();

  const links = [
    { path: '/dashboard/upload-data', label: 'Files', icon: faFile },
    { path: '/dashboard/upload-data/text', label: 'Text', icon: faAlignLeft },
    { path: '/dashboard/upload-data/website', label: 'Website', icon: faGlobe },
    { path: '/dashboard/upload-data/qa', label: 'Q&A', icon: faComments },
  ];

  return (
    <div className='data-sidebar-container'>
      <h2>Sources</h2>
      <ul>
        {links.map((item, index) => (
          <Link key={index} to={item.path} className={`${location.pathname === item.path ? 'active' : ''}`}>
            <li>
              <FontAwesomeIcon icon={item.icon} />
              {item.label}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default DataSidebar;
